.inbox-height {
    height: calc(100% - 64px);
}
.chat-height{
    max-height: calc(100vh - 190px);
}
.broadcast-height
{
    min-height: calc(100vh - 250px);
}

.contact-height
{
    min-height: calc(100vh - 290px);
}
.create-broadcast-height{
    height: calc(100vh - 140px);
}

.DraftEditor-root {
    min-width: 200px;
}
.img-filter{
    filter: brightness(0) invert(1);
}