.chat-bg{
    background-image: url('../../../../../public/assets/images/business/chat_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.bt-file{
    cursor: pointer !important;
}

.slick-dots {
    bottom: 5px;
}

.slick-dots li {
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    background-color: #fff;
    opacity: 1;
    margin: 0 5px;
  }
  
  .slick-dots li.slick-active {
    width: 18px !important;
    height: 8px !important;
    border-radius: 25%;
    background-color: #fff;
  }
  
  .slick-dots li button:before {
    content: '' !important;
    color: #fff;
    opacity: 0;
  }
  
  .slick-dots li.slick-active button:before {
    content: '';
    opacity: 0;
  }

  .client-chat-height {
    height: 80vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }