.facebook-button {
     background-color: #219EBC;
     border-radius: 6px;
     color: white;
     font-size: 16px;
     font-weight: 500;
     padding: 10px 20px;
     max-width: 160px;
     width: 100%;
     text-transform: capitalize;
   }
   
   .facebook-button:hover {
     background-color: #365899;
   }

   .social-slider .slick-dots{
    position: unset;
   }

   .social-slider .slick-dots li{
    background-color: #219EBC;
   }