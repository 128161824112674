.userProfile div{
     width: 68px !important;
     height: 68px !important;
     font-size: 30px !important;
}

.userProfileDetail div{
     width: 173px !important;
     height: 173px !important;
     font-size: 30px !important;
     border-radius: 10px !important;
}