.option-info:hover .option-description {
     display: block;
   }

.select-plan-signup{
     font-family: 'Quicksand', sans-serif;
}

.ReactFlagsSelect-module_secondaryLabel__37t1D{
     color: black !important;
     font-size: 14px !important;
   }
   
.ReactFlagsSelect-module_label__27pw9{
     color: black !important;
     font-size: 14px !important;
   }

.ReactFlagsSelect-module_selectOptions__3LNBJ {
     width: 135px;
}