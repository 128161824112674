.slick-dots li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background-color: #ccc;
  opacity: 1;
  margin: 0 5px;
}

.slick-dots li.slick-active {
  width: 18px !important;
  height: 8px !important;
  border-radius: 25%;
  background-color: #ccc;
}

.slick-dots li button:before {
  content: '' !important;
  color: #ccc;
  opacity: 0;
}

.slick-dots li.slick-active button:before {
  content: '';
  opacity: 0;
}
