.qr-code-border {
  color: #0BA861;
  border: 2px solid;
  clip-path: polygon(
    0 0,
    20% 0,
    20% 10px,
    80% 10px,
    80% 0,
    100% 0,
    100% 20%,
    calc(100% - 2px) 20%,
    calc(100% - 2px) 80%,
    100% 80%,
    100% 100%,
    80% 100%,
    80% calc(100% - 2px),
    20% calc(100% - 2px),
    20% 100%,
    0 100%,
    0 80%,
    10px 80%,
    10px 20%,
    0 20%
  );
  text-align: center;
  padding: 25px;
}

.templeteCss div button {
  background-color: #0CA861 !important;
}

