.typing-indicator {
     display: flex;
     align-items: center;
   }
   
   .typing-indicator .dot {
     /* background-color: #ccc; */
     border-radius: 50%;
     width: 8px;
     height: 8px;
     margin: 0 2px;
     animation: blink 1s infinite;
   }
   
   .typing-indicator .dot:nth-child(2) {
     animation-delay: 0.2s;
   }
   
   .typing-indicator .dot:nth-child(3) {
     animation-delay: 0.4s;
   }
   
   @keyframes blink {
     0% {
       opacity: 0.2;
     }
     20% {
       opacity: 1;
     }
     100% {
       opacity: 0.2;
     }
   }