.cssInTitle  h1{
    font-size: 37px;
    font-weight: 700;
  }

  .cssInTitle  h2{
    font-size: 37px;
    font-weight: 700;
  }
  
  .mainHeading h1{
    font-size: 68px;
    font-weight: 700;
  }

  @media only screen and (max-width: 650px) {
    .mainHeading h1{
        font-size: 28px;
        font-weight: 700;
      }
      .cssInTitle  h1{
        font-size: 28px;
        font-weight: 700;
      }
      .cssInTitle  h2{
        font-size: 28px;
        font-weight: 700;
      }
  }
    
  .cssInTitle br{
    display: none;
  }
  #myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}